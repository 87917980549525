export default defineNuxtRouteMiddleware((to, from) => {
	// skip middleware on server
	if (process.server) {
		// console.log('Server middleware/authuser.ts: to: ', to)
		// return
	}
	// skip middleware on client side entirely
	if (process.client) {
		// console.log('Client middleware/authuser.ts: to: ', to)
		// return
	}
	// or only skip middleware on initial client load
	const nuxtApp = useNuxtApp()
	if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) { /* empty */ }
	// console.log('App middleware/authuser.ts: to: ', to)
})
